<!--  -->
<template>
  <div class="">
    <div class="historychoose" v-show="$route.meta.showfater">
      <!-- <el-tabs :tab-position="tabPosition" type="border-card">
  <el-tab-pane label="单导联心电" class="history_tab"> -->
      <h2>请选择日期范围查询<span>{{ realName }}</span><span style="color:#4da1eb">八导联心电</span>检测记录</h2>
      <el-form ref="form" :model="form">
        <el-col :span="2"><div class="blank"></div></el-col>
        <el-form-item>
          <span slot="label">
            <span class="form-name"> 选择起始日期 </span>
          </span>
          <el-col :span="11" class="blank">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="form.date1"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-col :span="2"><div class="blank"></div></el-col>
        <el-form-item>
          <span slot="label">
            <span class="form-name"> 选择截止日期 </span>
          </span>
          <el-col :span="11" class="blank">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="form.date2"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <el-col :span="2"><div class="blank"></div></el-col>
        <el-form-item>
          <el-button class="button" type="primary" @click="querylistByTime()">确认查询</el-button>
          <!-- @click -->
          <el-button type="primary" plain @click="queryAll()">查询所有记录</el-button>
          <!-- @click -->
        </el-form-item>
      </el-form>

      <!-- 添加搜索\刷新功能 -->
      <!-- <div style="text-align: right">
        <el-button circle size="small"
          ><i class="el-icon-search"></i
        ></el-button>
        <el-button circle size="small"
          ><i class="el-icon-refresh"></i
        ></el-button>
        <el-button circle size="small"
          ><i class="el-icon-s-grid"></i
        ></el-button>
      </div> -->
      <!-- 表格内容 -->
      <el-table :data="tableData" height="600" :header-cell-style="tableHeaderColor" border stripe style="width: 100%"
        :cell-style="{ fontSize: '16px' }" v-loading="loadingShow" element-loading-text="数据正在加载中">
        <el-table-column type="index" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="记录时间" align="center">
        </el-table-column>
        <el-table-column prop="conclusion" label="结论" align="center">
        </el-table-column>
        <el-table-column prop="read" label="操作" align="center">
          <template slot-scope="scope">
            <el-popover placement="right" trigger="hover" content="查看详细内容/报告">
              <el-button @click="handleClick(scope.row)" type="text" size="medium" style="font-size: 16px;"
                slot="reference">查看记录
              </el-button>
            </el-popover>

          </template>
        </el-table-column>
        
        <!-- 复核模块 -->
        <el-table-column prop="check" label="咨询" align="center" v-if="isCrossPlatJump">
          <template slot-scope="scope">
            <el-popover placement="right" trigger="click">
              <p>输入您的症状描述</p>
              <div style="text-align: right; margin: 0">
                <el-input v-model="scope.row.userDescribe" type="textarea" placeholder="添加症状描述"></el-input>
                <el-button size="mini" type="text">取消</el-button>
                <el-button type="primary" size="mini" @click="Check2Doctor(scope.row)">确定</el-button>
              </div>
              <el-button type="primary" size="medium" style="font-size: 14px;" slot="reference">医生复核
              </el-button>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column prop="" label="复核状态" align="center" v-if="isCrossPlatJump">
          <template slot-scope="scope">
            <el-button type="primary" plain size="medium" style="font-size: 14px;" @click=CheckResult(scope.row)>查看复核
            </el-button>
          </template>
        </el-table-column>

        <el-table-column label="删除" align="center">
          <template slot-scope="scope">
            <!-- <el-button
            size="mini"
            type="danger"
            @click.native.prevent="handleDelete(scope.$index, tableData)">删除</el-button> -->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="handleDelete(scope.$index, scope.row)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- </el-tab-pane>
  </el-tabs> -->
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabPosition: "left",
      checkdataListVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      tableData: [],
      checkdataList: [],
      header:{},
      isCrossPlatJump: true,
      loadingShow: true,
      realName:'',
    };
  },
  methods: {
    //删除功能。目前简单用假数据代替，还不涉及数据库链接
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //设置表头行的样式
    tableHeaderColor() {
      return "color:#000 ;font-size:18px;text-align:center";
    },

    handleClick(e) {
      console.log(e);
      // this.staticRecordId = e.sid
      this.$router.push({
        name: 'HistoryEight2_women',
        query: {
          "staticRecordId": e.seid,
          "staticRecordCreatetime": e.createTime,
          "staticRecordDuration": e.duration,
        }
      })
    },

    filterTag(value, row) {
        return row.checkStatus === value;
    },
    TimeProcess(time) {
      let y = time.getFullYear();
      let MM = time.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = time.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + MM + '-' + d
    },
    getDataList() {
      this.loadingShow = true
      if (this.$store.state.module.crossPlatform) {
        console.log("object11111");
          this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http.adornHeader(this.header)
      this.$http({
        url: this.$http.adornUrl("/personal/staticecg8/list"),
        method: "post",
        data: this.$http.adornData({ uid: this.uid }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          // console.log("看看datalist数据");
          // console.log(data)
          this.datalist = data.staticEcg8Entities;
          this.tableData = new Array(this.datalist.length)
          this.tableData = this.datalist.reverse()
          // console.log(this.tableData)
          var timelist = new Array(this.datalist.length)
          for (let i = 0; i < this.datalist.length; i++) {
            this.$set(this.tableData[i], "conclusion", '') //添加结论数据
            this.$set(this.tableData[i], "checkStatus", '')//添加复核状态
            this.$set(this.tableData[i], "userDescribe", '')//添加症状描述
            timelist[i] = this.tableData[i].createTime.substring(0, 10)
          }
          this.latter = timelist[0]
          this.former = timelist.pop()
          this.geConclusionList(this.former, this.latter)
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });

    },

    queryAll() {
      this.getDataList()
    },

    querylistByTime() {
      this.loadingShow = true
      this.startTime = this.TimeProcess(this.form.date1)
      this.endTime = this.TimeProcess(this.form.date2)
      // console.log(this.startTime)
      // console.log(this.endTime)
      this.$http({
        url: this.$http.adornUrl("/personal/staticecg8/getRecordByTime"),
        method: "post",
        data: this.$http.adornData({ uid: this.uid, startTime: this.startTime, endTime: this.endTime }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          console.log("看看数据");
          // console.log(data);
          this.tableData = data.staticEcgEntities;
          for (let i = 0; i < this.tableData.length; i++) {
            this.$set(this.tableData[i], "conclusion", '') //添加结论数据
          }
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
      this.geConclusionList(this.startTime, this.endTime)
    },

    geConclusionList(former, latter) {
      // 根据uid、起止日期、返回所有静态心电结论
      this.$http.adornHeader(this.header)
      if (this.$store.state.module.crossPlatform) {
          this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http({
        url: this.$http.adornUrl("/personal/staticecg8/getConclusionByTime"),
        method: "post",
        data: this.$http.adornData({
          uid: this.uid, startTime: former, endTime: latter
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.conclusion = data.conclusions
          console.log(this.conclusion);
          var xingouDisList = ['fc', 'fxyb', 'fxzb', 'jjxzb',  'ssxxdgs', 'sxxdgs', 'sxyb', 'sxzb', 'xdgh', 'xdgs', 'xlbq','Idfs','IIdIfs','IIdIIfs','zszcdzz','yszcdzz','zqfz','dxyjzhz','dprzhz','byxyjzhz','sttg','styd','tbxf','tbgdyc','blxqb']
          var XingouDisName = ['房颤', '交界性逸博', '房性早搏', '交界性早搏',  '室上性心动过速', '室性心动过速', '室性逸博', '室性早搏', '窦性心动过缓', '窦性心动过速', '窦性心律不齐','房室传导阻滞','房室传导阻滞','房室传导阻滞','室内传导阻滞','室内传导阻滞','室内传导阻滞','预激综合征','预激综合征','预激综合征','ST段抬高','ST段压低','T波倒置','T波高尖','病理性Q波']
          for (let i = 0; i < data.conclusions.length; i++) {
            this.conclusion[i] = JSON.parse(this.conclusion[i])
            var abnormalItem = []
            var tmp = []
            var qtycmap = { 0: "ST-T正常", 1: "T波倒置", 2: "ST段抬高", 3: "ST段压低" }//心梗相关疾病的map
            for (var j in this.conclusion[i]) {
              let a = xingouDisList.indexOf(j)
              if (a > -1) {
                if (this.conclusion[i][j] !=0 ) {
                    tmp.push(XingouDisName[a])
                }
              }
            }
            abnormalItem = Array.from(new Set(tmp))
            if (abnormalItem != '') {
              this.tableData[i].conclusion = "心率" + this.conclusion[i].xinlv + "；" + '异常项：' + abnormalItem
            }
            else {
              this.tableData[i].conclusion = "心率" + this.conclusion[i].xinlv + '；正常'
            }
          }
          
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });

    },

    // 心狗医生--复核
    Check2Doctor(e) {
      console.log(e.userDescribe)
      //医生复核
      this.$http({
        url: this.$http.adornUrl("/personal/relationconsult/createRecord"),
        method: "post",
        data: this.$http.adornData({
          uid: this.uid, type: "ecg8_static", userDescribe: e.userDescribe, recordId: e.sid
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
        console.log('返回的数据')
        console.log(data)
        e.checkStatus = '待复核'
        this.$message({
          message: '成功向医生发出复核申请',
          type: 'success'
        })}
        else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消申请'
        });
      });

    },
    CheckResult(e) {
      this.checkdataList=[]
      this.checkdataListVisible=true
      console.log(e)
      this.$http({
        url: this.$http.adornUrl("/personal/relationconsult/getRecord"),
        method: "post",
        data: this.$http.adornData({type:"ecg8_static", recordId:e.sid,}),
      }).then(({ data }) => {
        if (data && data.code === 0) {
        this.checkdataList = data.list
        console.log(this.checkdataList)
      }
      else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      })
    },
    getinfo() {
      this.$http({
        url: this.$http.adornUrl('/personal/info/getBasicInfo'),
        method: 'get',
        params: this.$http.adornParams({ uid: this.uid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据")
          this.realName = data.realName
          // this.age = data.age
          // this.gender = data.gender
          // var genderMap = { 0: "女", 1: "男" }
          // this.gender = genderMap[this.gender]
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    }
  },
  created() {
    this.uid = this.$store.state.women_baby.uid
  },
  mounted() {
    if(this.$route.query.uid != null && this.$route.query.uid != undefined) {
      this.userId = this.$route.query.uid
    }
    this.getDataList()
    this.getinfo()
    this.isCrossPlatJump = !this.$store.state.module.crossPlatform
  }
};
</script>
<style scoped>
.blank {
  border-radius: 4px;
  height: 18px;
}
.form-name {
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
}
</style>